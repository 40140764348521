
import _ from 'lodash';


function getUnique(all) {
    const arr = [];

    all.edges.map(({ node }) => {
        const title = node.frontmatter.course ? node.frontmatter.course : node.frontmatter.title
        const slug = node.frontmatter.courseurl ? '/' + node.frontmatter.courseurl : node.fields.slug
        const logo = node.frontmatter.logo;
        const excerpt = node.excerpt;
        const date = node.frontmatter.date;
        const tags = node.frontmatter.tags
        // const image = node.frontmatter.image && node.frontmatter.image.childImageSharp.fluid
        arr.push(
            {
                title,
                slug,
                logo,
                excerpt,
                date,
                tags
            }
        )

    })
    return _.uniqBy(arr, 'title')

}


export default getUnique;