import React from "react";

const formStyle = {
  marginTop: "1rem",
  marginBottom: "2rem",
  padding: "0.7rem",
  paddingBottom: .01,
  background: "#fff",
  borderBottom: "6px solid #ccc",
  borderRadius: "11px",
  border: "1px solid #ccc"
};

export default () => {
  return (
    <div style={formStyle}>
      <form
        action="https://tinyletter.com/reactgo"
        method="post"
        target="popupwindow"
        onSubmit={() =>
          window.open(
            "https://tinyletter.com/reactgo",
            "popupwindow",
          )}
        className={"embeddable-buttondown-form "}
      >
        <h4 className="animate-text">Get my latest tutorials</h4>

        <div>
          <label htmlFor="b-email" className="label">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="b-email"
            className="email"
            placeholder="user@email.com"
            required
            style={{ width: "11rem" }}
          />
        </div>
        <div><input type="hidden" value="1" name="embed" /></div>
        <div>
          <input
            type="submit"
            className="subs"
            style={{
              border: "1px solid #b3adad",
              margin: "1rem auto",
              fontWeight: "400",
              color: "rgba(0, 0, 0, 0.69)",
              background: "rgba(202, 255, 168, 0.66)",
              marginLeft: "1rem",
            }}
            value="Send me"
          />
        </div>
      </form>
    </div>
  );
};
