import React from 'react';
import sai from '../../img/ccc.jpeg'
import './my-page.css'
import kofiimg from './kofi.png'


function Mypage() {

    return (
        <div className="my-page">
            <img src={sai} className="round-img" loading="lazy" width="164" height="164"
                alt={`reactgo.com author`}
            />
            <p>Hi, my name is Sai Gowtham, I write tutorials on Modern JavaScript
            that have helped a
            lot of people to understand the hard concepts in an easy way.
            </p>
            {/* <p style={{ paddingTop: "1rem", paddingBottom: ".2rem" }}>e-mail: saigowthamr@gmail.com</p> */}
            <div className="sponsor">
                <a href="/advertise">Become a Sponsor</a>
            </div>
            {/* <div className="cofi">
                <a target="_blank" rel="nofollow noopener noreferrer" href="https://ko-fi.com/O5O510H4N" className="cofi-link" ><img src={kofiimg} alt="kofi" className="cofi-img" />Buy me a coffee!</a>
            </div> */}
        </div>
    )
}


export default Mypage;