import React from 'react'
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import ReactDOMServer from 'react-dom/server';
// import Balance from 'react-icons/lib/io/arrow-graph-down-right'
import getUnique from '../new';
import '../blog.css';
import '../list.css'
import './main.css'
import Subscribe from '../subscribe';
import Mypage from '../mypage';
import CarbonMain from '../carbonads/main';
import Recommended from '../recommended';
import Pagination from '../pagination';
// import Primis from '../carbonads/Primis';

class MainUi extends React.Component {

  componentDidMount() {
    this.attachComponent(this.article.getElementsByTagName('a')[1]);
  }


  attachComponent(el) {
    let com = ReactDOMServer.renderToString(<Recommended tags={['javascript']} />);
    el.insertAdjacentHTML('afterend', com);
  }

  render() {
    const { allMarkdownRemark } = this.props.data;
    const pageContext = this.props.pageContext;
    const finalData = getUnique(allMarkdownRemark);
    const tags = this.props.tags;

    return (
      <div className="post-list auto bglight ">
        <div className="padding-top3">
          <div className="ui-wrapper">
            <div className="content-wrapper">
              <main className="content-box">
                <div className="post-setup" ref={node => this.article = node}>
                  {finalData.map((post, i) => (
                    <Link to={post.slug} key={i} className="killer">
                      <div>
                        {post.logo && (
                          <span className="list-logo">
                            <img
                              src={post.logo}
                              style={{ margin: 0 }}
                              alt={post.courseurl}
                            />
                          </span>
                        )}
                        <h2>{post.title}</h2>
                        <time className="date">{post.date}</time>
                        <p className="excerpt">{post.excerpt}</p>
                      </div>
                    </Link>
                  ))}
                </div>

                <Pagination pageContext={pageContext} />

              </main>
              <aside className="bar-box">
                <Subscribe />
                {/* <div className="shadow">
                  <strong className="shadow-title">Learn Web developement<sub className="shadow-icon"><Balance size={'36px'} /></sub> </strong>
                  If you want to learn web development for free, Linkedin learning is currently offering a 30 days trial to
                   access all courses you can signup <a target="_blank"
                    rel="nofollow" href="https://linkedin-learning.pxf.io/Dmebo">
                    right here</a>.
                   <div className="link-fo">
                    <Link to="/aff-disclosure" >About this Link</Link>
                  </div>
                </div> */}
                {/* <Primis /> */}

                <Mypage />
                {
                  tags.length && <div className="similar-articles">
                    {/* <h2>All tutorials</h2> */}
                    {tags.map((tag) => {
                      return <Link to={`/tutorials/${kebabCase(tag)}/`} key={tag}>{`${tag}`}</Link>
                    })}
                  </div>
                }

              </aside>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainUi